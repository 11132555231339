@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

@font-face {
	font-family: 'calpslight';
	src: url('./assets/fonts/typesketchbook_-_calps-light-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-light-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'zoojaregular';
	src: url('./assets/fonts/aerotype_-_zooja-webfont.woff2') format('woff2'),
		url('./assets/fonts/aerotype_-_zooja-webfont.woff') format('woff');

	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'calpsmedium';
	src: url('./assets/fonts/typesketchbook_-_calps-medium-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-medium-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'calpsbold';
	src: url('./assets/fonts/typesketchbook_-_calps-bold-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-bold-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Calps';
	src: url('./assets/fonts/typesketchbook_-_calps-bold-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-bold-webfont.woff')
			format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Calps';
	src: url('./assets/fonts/typesketchbook_-_calps-medium-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-medium-webfont.woff')
			format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Calps';
	src: url('./assets/fonts/typesketchbook_-_calps-regular-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-regular-webfont.woff')
			format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Calps';
	src: url('./assets/fonts/typesketchbook_-_calps-semilight-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-semilight-webfont.woff')
			format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Calps';
	src: url('./assets/fonts/typesketchbook_-_calps-semilight-italic-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-semilight-italic-webfont.woff')
			format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Calps';
	src: url('./assets/fonts/typesketchbook_-_calps-light-webfont.woff2')
			format('woff2'),
		url('./assets/fonts/typesketchbook_-_calps-light-webfont.woff')
			format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'SF Pro Rounded';
	src: url('./assets/fonts/SF-Pro-Rounded-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

.DraftEditor-root {
	width: 100%;
	padding: 6px 15px;
}

.message_contacts_compose_dropdown {
	border-radius: 8px;
	background-color: #f8f8f8;
	border: 1px solid #dbdadb;
	box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.15);
}

.message_contacts_compose_dropdown .ant-select-item {
	padding: 0px;
}

.message_contacts_compose_dropdown
	.ant-select-item-option-active
	.ant-select-item-option-content
	svg {
	fill: none;
}

/* .message_contacts_compose_dropdown
	.ant-select-item-option-active
	.ant-select-item-option-content
	> div {
	background-color: #00a79d;
} */

.message_contacts_compose_dropdown
	.ant-select-item-option-active
	.ant-select-item-option-content
	div {
	color: #f8f8f8;
}

.text-area-automated-active {
	border: none !important;
	opacity: 0.9;
	background: #f3f3f3 0% 0% no-repeat padding-box !important;
}

.text-area-automated-inactive {
	border: none !important;
	opacity: 0.4;
	background: #f3f3f3 0% 0% no-repeat padding-box !important;
}

@keyframes donut-fill {
	to {
		stroke-dasharray: 0 100;
	}
}

@keyframes text-slideup {
	from {
		opacity: 0;
		transform: translateY(0%);
	}
	to {
		opacity: 1;
		transform: translateY(-50%);
	}
}

.donut {
	width: 12.5rem;
	height: 12.5rem;
	position: relative;
	margin: auto;
}

.donut__frame,
.donut__circle {
	stroke-width: 0.125rem;
	fill: none;
}

.donut__frame {
	stroke: #ccc;
}

.donut__canvas {
	width: 100%;
	height: 100%;
	display: block;
	position: relative;
	z-index: 2;
}

.donut__circle {
	stroke: #000;
	stroke-linecap: round;
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
	animation: donut-fill 2s reverse;
}

.donut__circle[stroke-dasharray='0 100'] {
	stroke: none;
}

.donut__text {
	font-family: monospace;
	font-size: 3rem;
	letter-spacing: -0.15rem;
	text-align: center;
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	z-index: 3;
	transform: translateY(-50%);
	animation: text-slideup 2s linear;
}

.donut.is--negative .donut__circle {
	transform: rotate(-90deg) scaleY(-1);
}

.donut-complex text {
	font-family: monospace;
	font-size: 1rem;
	font-weight: bold;
	text-shadow: 0 0 0.5rem #fff;
	opacity: 0;
	transition: opacity 0.35s ease-in-out 1s;
}

.donut-complex circle {
	fill: none;
	transition: stroke-dashoffset 1s ease-in-out;
}

.donut-complex:not(.donut-complex--loaded) circle {
	stroke-dashoffset: 0;
}

.donut-complex--loaded text {
	opacity: 1;
}

.antd-unstyled-custom-collapse .ant-collapse-item,
.antd-unstyled-custom-collapse .ant-collapse-header,
.antd-unstyled-custom-collapse .ant-collapse-content-box {
	margin: 0px !important;
	border: 0px !important;
	padding: 0px !important;
	overflow: hidden !important;
	border-radius: 0px !important;
	background: transparent !important;
}

.no-margin-svg {
	svg {
		margin: 0px;
	}
}
